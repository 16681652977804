body {
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
  background-color: black;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100vw, 0, 0); /* The image width */
  }
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%
  }
}

.result-grid::after {
  content: "";
  width: 31%;
}

:root {
  --animate-delay: 0.5s !important;
}

.ReactCollapse--collapse {
  transition: height 500ms ease;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
 -webkit-animation-name: ticker;
         animation-name: ticker;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
}

::-webkit-scrollbar {
    display: none;
}

.nav-right-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

input {
  border-radius: 0px;
}

input:focus{
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -moz-transition: none;
  -webkit-transition: none;
}

textarea:focus{
  outline: none;
  border:none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -moz-transition: none;
  -webkit-transition: none;
}

a {
  display: block;
  text-decoration: none;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:active { text-decoration: none; }

br {
  display: none;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

div[disabled]
{
  pointer-events: none;
  background-color: lightgray;
}

.divider {
  width: 80%;
  height: 1px;
  background-color: #4A4A4A;
}

.footer-container {
  padding-bottom: 48px;
}

.footer-column {
  margin-top: 48px;
  margin-left: 48px;
  margin-right: 48px;
  display: inline-block;
  vertical-align: top;
}

.footer-title {
  font-family: 'Jost-Regular';
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 16px;
}

.desktop {
  display: block;
}

.App {
  width: 100%;
  height: 100%;
}

.mynav {
  position: absolute;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  width: 100vw;
  top: 0;
  height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.nav-cta {
  margin-left: auto;
}

/* Buttons */
.button {
  display: block;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.email-button {
  z-index: 2;
  margin-left: 12px;
  background-color: #FFFFFF;
  cursor: pointer;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-small {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

/* Fonts */
@font-face {
  font-family: 'Jost-ExtraLight';
  src: url('./fonts/Jost-ExtraLight.ttf');
}

@font-face {
  font-family: 'Jost-Light';
  src: url('./fonts/Jost-Light.ttf');
}

@font-face {
  font-family: 'Jost-Regular';
  src: url('./fonts/Jost-Regular.ttf');
}

@font-face {
  font-family: 'Jost-Medium';
  src: url('./fonts/Jost-Medium.ttf');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url('./fonts/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'DMSans-Light';
  src: url('./fonts/DMSans-Light.ttf');
}

@font-face {
  font-family: 'DMSans-Regular';
  src: url('./fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: url('./fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'Victoria';
  src: url('./fonts/Victoria.ttf');
}




/* Colors */
.accent {
  color: #88A9C3;
}

.blue {
  color: #000814;
}

.cream-fill {
  background-color: #F0E6D4;
}

.white {
  color: white;
}

.offwhite {
  color: #FAFAFA;
}

.place-card {
  background-color: #FAFAFA;
  color: #000814;
  transition: all 0.4s ease;
}

.place-card:hover {
  background-color: #000814;
  color: white;
}

.lightergray {
  color: #F1F1F1;
}

.lightgray {
  color: #787878;
}

.gray {
  color: #4A4A4A;
}

.darkgray {
  color: #1A1A1A;
}

.offblack {
  color: #0D0D0D;
}

.black {
  color: black;
}

.accent-fill {
  background-color: #88A9C3;
}

.blue-fill {
  background-color: #000814;
  -webkit-text-stroke-color: #000814;
}

.white-fill {
  background-color: white;
}

.offwhite-fill {
  background-color: #FAFAFA;
}

.lightergray-fill {
  background-color: #F1F1F1;
}

.lightgray-fill {
  background-color: #787878;
}

.gray-fill {
  background-color: #4A4A4A;
}

.darkgray-fill {
  background-color: #1A1A1A;
}

.offblack-fill {
  background-color: #0D0D0D;
}

.black-fill {
  background-color: black;
}

/* Text styles */
.primary-header1 {
  font-family: Baskerville;
  font-size: 64px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header2 {
  font-family: Baskerville;
  font-size: 48px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header3 {
  font-family: Baskerville;
  font-size: 40px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header4 {
  font-family: Baskerville;
  font-size: 36px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header5 {
  font-family: Baskerville;
  font-size: 30px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header6 {
  font-family: Baskerville;
  font-size: 24px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header7 {
  font-family: Baskerville;
  font-size: 20px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.primary-header8 {
  font-family: Baskerville;
  font-size: 18px;
  letter-spacing: -0.05em;
  -webkit-text-stroke-width: 0.009em;
  -webkit-text-stroke-color: white;
}

.secondary-header1 {
  font-family: 'Jost-Light';
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 18px;
}

.secondary-header2 {
  font-family: 'Jost-Light';
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 16px;
}

.secondary-header3 {
  font-family: 'Jost-Light';
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 14px;
}

.secondary-header4 {
  font-family: 'Jost-Light';
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 12px;
}

.normal {
  font-family: 'Jost-Light' !important;
}

.bold {
  font-family: 'Jost-Regular' !important;
}

.xbold {
  font-family: 'Jost-Medium' !important;
}

.nav-header {
  font-size: 32px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.footer-header {
  cursor: pointer;
  transition: all 0.4s ease;
}

.body1 {
  font-family: 'Jost-Light';
  font-size: 20px;
  letter-spacing: -0.03em;
}

.body2 {
  font-family: 'Jost-Light';
  font-size: 18px;
  letter-spacing: -0.02em;
}

.body2-hover {
  font-family: 'Jost-Light';
  font-size: 18px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.body3 {
  font-family: 'Jost-Light';
  font-size: 16px;
  letter-spacing: -0.02em;
}

.body3-hover {
  font-family: 'Jost-Light';
  font-size: 16px;
  transition: all 0.4s ease;
  cursor: pointer;
}


.body4 {
  font-family: 'Jost-Light';
  font-size: 15px;
  letter-spacing: -0.02em;
}

.body5 {
  font-family: 'Jost-Light';
  font-size: 14px;
  letter-spacing: -0.02em;
}

.body1-bold {
  font-family: 'Jost-Regular';
  font-size: 24px;
}

.button-text {
  font-family: 'Jost-Regular';
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 24px;
  color: #000000;
}

.button-text-medium {
  font-family: 'Jost-Regular';
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 14px;
  color: #000000;
}

.button-text-small {
  font-family: 'Jost-Regular';
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 12px;
  color: #000000;
}

/* Alignment */
.align-right {
  width: 60%;
  text-align: right;
  align-self: flex-end;
  margin-right: 96px;
}

/* Spacing standards */
.vertical-spacing-xxsmall {
  margin-top: 2px;
}

.vertical-spacing-xsmall {
  margin-top: 4px;
}

.vertical-spacing-small {
  margin-top: 12px;
}

.vertical-spacing-medium {
  margin-top: 24px;
}

.vertical-spacing-m2 {
  margin-top: 36px;
}

.vertical-spacing-large {
  margin-top: 48px;
}

.vertical-spacing-xlarge {
  margin-top: 64px;
}

.vertical-spacing-xxlarge {
  margin-top: 96px;
}

.horizontal-spacing-xsmall {
  margin-left: 4px;
}

.horizontal-spacing-small {
  margin-left: 12px;
}

.horizontal-spacing-medium {
  margin-left: 24px;
}

.horizontal-spacing-large {
  margin-left: 48px;
}

.horizontal-spacing-xlarge {
  margin-left: 64px;
}

.horizontal-spacing-xxlarge {
  margin-left: 96px;
}

.hidden {
  visibility: hidden;
}

@media only screen and (max-width: 900px) {

  .primary-header1 {
    font-size: 50px;
    -webkit-text-stroke-width: 0.005em;
  }

  .primary-header2 {
    font-size: 36px;
    -webkit-text-stroke-width: 0.005em;
  }

  .primary-header3 {
    font-size: 34px;
    -webkit-text-stroke-width: 0.005em;
  }

  .primary-header4 {
    font-size: 30px;
    -webkit-text-stroke-width: 0.005em;
  }

  .primary-header5 {
    font-size: 28px;
    -webkit-text-stroke-width: 0.005em;
  }

  .primary-header6 {
    font-size: 26px;
    -webkit-text-stroke-width: 0.005em;
  }

  .primary-header7 {
    font-size: 24px;
    -webkit-text-stroke-width: 0.005em;
  }

  .secondary-header1 {
    font-size: 18px;
  }

  .secondary-header2 {
    font-size: 16px;
  }

  .secondary-header3 {
    font-size: 14px;
  }

  .secondary-header4 {
    font-size: 13px;
  }

  .body1 {
    font-size: 20px;
  }

  .body2 {
    font-size: 18px;
  }

  .body3 {
    font-size: 17px;
  }

  .body4 {
    font-size: 16px;
  }

  .body5 {
    font-size: 15px;
  }


  .button {
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
  }

  .button-text {
    font-size: 18px;
  }

  .hidden {
    display: none;
  }

  .mynav {
    padding-left: 12px;
    padding-right: 12px;
    height: 8vh;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .vertical-spacing-small {
    margin-top: 12px;
  }

  .vertical-spacing-medium {
    margin-top: 24px;
  }

  .vertical-spacing-m2 {
    margin-top: 36px;
  }

  .vertical-spacing-large {
    margin-top: 40px;
  }

  .vertical-spacing-xlarge {
    margin-top: 48px;
  }

  .vertical-spacing-xxlarge {
    margin-top: 64px;
  }

  br {
    display: inline;
  }

  .footer-column {
    display: block;
  }

  .nav-header {
    font-size: 24px;
  }

  .horizontal-spacing-xxlarge {
    margin-left: 24px;
  }

  .horizontal-spacing-large {
    margin-left: 12px;
  }

  .footer-header {
    font-size: 32px;
  }
}
