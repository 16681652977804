.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.standard-number {
  font-family: 'Jost-Light';
  font-size: 72px;
}

.map-container {
  height: 55vh;
  width: 100%;
}

.standard-badge {
  width: 75%;
  padding: 24px 24px 12px 0px;
  box-sizing: border-box;
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.standard-stamp {
  flex: 0 0 auto;
  height: 120px;
  width: 120px;
  margin-left: -36px;
  margin-right: 24px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-marker {
  background-image: url('../images/marker.png');
  background-size: cover;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.hero-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.hero-img-secondary-container {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hero-img-primary {
  width: 65%;
  height: 100%;
  object-fit: cover;
  margin-right: 12px;
}

.hero-img-secondary {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.view-all-button {
  padding: 4px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.dim-view {
  background-color: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
}

.notify-modal {
  text-align: center;
  position: fixed;
  top: 150vh;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 50vw;
  z-index: 12;
  transition: all 0.5s ease;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 15px;
}

.change-modal {
  text-align: left;
  position: fixed;
  top: 150vh;
  left: 30%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 40vw;
  z-index: 12;
  transition: all 0.5s ease;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 10px;
}

.creator-detail-modal {
  position: fixed;
  top: 150vh;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 100%;
  height: 100vh;
  z-index: 12;
  transition: all 0.5s ease;
  padding: 0px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.exp-detail-modal {
  position: fixed;
  top: 150vh;
  left: 0;
  opacity: 0;
  width: 60%;
  height: 92vh;
  z-index: 12;
  transition: all 0.5s ease;
  padding: 0px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.onboarding-modal {
  position: fixed;
  top: 150vh;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 92vh;
  z-index: 10;
  transition: all 0.5s ease;
  box-sizing: border-box;
  overflow-y: scroll;
}

.exp-detail-slide-up {
  top: 8vh;
  opacity: 1;
}

.notify-input {
  width: 100%;
  background-color: rgba(0,0,0,0);
  border-style: none;
  border-bottom: 0.8px solid #787878;
  border-radius: 0px;
  text-align: left;
  padding: 0px;
}

.carousel-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 200vh;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  height: 95vh;
  z-index: 10;
  transition: all 0.5s ease;
  padding: 24px 48px;
  box-sizing: border-box;
}

.gallery-modal {
  overflow-y: scroll;
  position: fixed;
  top: 200vh;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  height: 95vh;
  z-index: 9;
  transition: all 0.5s ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.result-modal {
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 150vh;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  height: 95vh;
  z-index: 12;
  transition: all 0.5s ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.media-category-row {
  display: flex;
  flex-direction: row;
}

.media-category-text {
  font-family: 'Jost-Light';
  padding-bottom: 2px;
  font-size: 18px;
  text-transform: capitalize;
  margin: 0px 24px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.gallery-masonry-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  width: 70%;
}

.gallery-masonry-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
}

.gallery-masonry-img {
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
  height: auto;
  transition: all 0.4s ease;
}

.carousel-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.carousel-img {
  margin-top: 0;
  height: 70vh;
  width: auto;
  object-fit: cover;
  max-width: 90vw;
}

.play-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 8;
}

.carousel-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s ease;
}

.full-slide-up {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.slide-up {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.change-slide-up {
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.x-icon {
  position: absolute;
  z-index: 24;
  top: 24px;
  right: 24px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.video-container {
  height: 85vh;
  width: 100vw;
  position: relative;
  margin-bottom: -24px;
}

.video-hero {
  height: 100%;
  width: 100%;
  margin-top: 0;
  object-fit: cover;
}

.see-all-button {
  padding: 12px 24px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.full-content-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s ease;
}

.medium-content-container {
  padding: 48px 0px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.narrow-content-container {
  padding: 84px 0px;
  width: 55vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 85%;
  align-self: center;
}

.booking-box {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  padding: 0px 48px 36px;
  display: flex;
  flex-direction: column;
}

.feature-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.book-button {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 12px;
  transition: all 0.4s ease;
  align-self: center;
}

.book-button-text {
  color: black;
  font-family: 'Jost-Regular';
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 16px;
}

.highlights-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.highlight-hero {
  width: 100%;
  height: 55vh;
  object-fit: cover;
  transition: width 0.5s, height 0.5s;
  cursor: pointer;
  border-radius: 10px;
}

.highlight-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.highlight-media {
  width: 49%;
  height: 30vh;
  object-fit: cover;
  transition: width 0.5s, height 0.5s;
  cursor: pointer;
  border-radius: 10px;
}

.itinerary-section {
  width: 70%;
  align-self: center;
}

.itinerary-item {
  display: flex;
  flex-direction: row;
}

.itinerary-item-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itinerary-icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #88A9C3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itinerary-line {
  width: 1px;
  height: 120px;
}

.itinerary-item-right {
  text-align: left;
}

.included-section {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.included-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.included-item {
  width: 49.5%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 5px;
}

.detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
}

.detail-divider {
  height: 0.5px;
  width: 100%;
}


@media only screen and (max-width: 900px) {
  .narrow-content-container {
    width: 90vw;
    padding: 36px 0px;
  }

  .gallery-modal {
    height: calc(var(--vh, 1vh) * 95);
    padding: 24px 0px 0px 0px;
    overflow-x: hidden;
  }

  .gallery-masonry-container {
    flex: 25;
    margin-left: 4px;
    margin-right: 4px;
    width: 100%;
  }

  .gallery-masonry-column {
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
  }

  .gallery-masonry-img {
    margin-top: 4px;
  }

  .highlight-media {
    width: 49%;
    height: 30vw;
  }

  .highlight-hero {
    width: 100%;
    height: 50vw;
    object-fit: cover;
    transition: width 0.5s, height 0.5s;
    cursor: pointer;
  }

  .booking-box {
    padding: 0px 24px 24px;
  }

  .itinerary-section {
    width: 100%;
  }

  .included-row {
    margin-top: 0px;
    flex-direction: column;
  }

  .included-item {
    margin-top: 12px;
    width: 100%;
    height: 80px;
  }

  .header-section {
    margin-top: 0px;
    width: 100%;
  }

  .carousel-img-container {
    display: flex;
    flex: 8;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .carousel-img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .carousel-modal {
    height: calc(var(--vh, 1vh) * 95);
    padding: 24px 0px;
  }

  .video-container {
    height: 50vh;
    width: 100vw;
    position: relative;
    margin-bottom: -24px;
  }

  .notify-modal {
    width: 90vw;
    padding: 24px;
    border-radius: 15px;
  }

  .x-icon {
    top: 20px;
    right: 12px;
  }

  .standard-badge {
    width: 100%;
    margin-top: 64px;
    padding: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .standard-stamp {
    flex: 0 0 auto;
    height: 80px;
    width: 80px;
    margin-top: -48px;
    margin-left: -12px;
    margin-bottom: 12px;
  }

  .standard-number {
    font-family: 'Jost-Light';
    font-size: 40px;
  }

  .media-category-row {
    display: flex;
    flex: 1;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  .media-category-text {
    justify-content: center;
    width: 100%;
    display: flex;
    flex: 0 0 auto;
  }
}
